const TOS = [
  {
    lang: "en",
    content: `
    Welcome to Creatory website and/or Creatory mobile app. Please read these Terms of Service (including all Annexes) ("<strong>Terms</strong>") carefully. These Terms govern your use and access of the Platform as a user and is agreed between you ("<strong>User</strong>", "<strong>you</strong>", "<strong>your</strong>") and <strong>Creatory</strong> on the date you click the "<strong>I Accept</strong>" button or any other similar button to complete your online sign-up process to become a User on the Platform ("<strong>Effective Date</strong>").
    <br><br>
    <strong>BY REGISTERING FOR AND USING THE SERVICES OF CREATORY, YOU AGREE TO BE BOUND BY THESE TERMS, AND ALL POLICIES OF CREATORY AVAILABLE AT <a href="HTTPS://CREATORY.ID/TOS">HTTPS://CREATORY.ID/TOS</a> AND <a href="HTTPS://CREATORY.ID/POLICY">HTTPS://CREATORY.ID/POLICY</a> AND ARE INCORPORATED BY REFERENCE.</strong>
    <br><br>
    Creatory may change any of these Terms, and any fees, procedures and policies governing the Services or the Platform at any time and your continued use of the Services or the Platform will constitute your acceptance of such changes. If you do not agree to any such changes, you must stop using the Services and/or the platform. For the avoidance of doubt, newer versions of the Terms supersede older versions.
    <ol>
      <li><strong>Services and Fees</strong>
        <ol>
          <li type="A"><strong>Services.</strong> The Services provided by Creatory based on these Terms are classified as:
            <ol className="parens-around">
              <li><strong>Tipping,</strong> is the service that allows you to receive support in the form of donation from your fans and/or to give donation to the creator registered on Creatory in accordance with the applicable terms and conditions. Your donations that have been received by the creator are non-refundable.</li>
              <li><strong>Hypes,</strong> is the service that allows you to participate in and complete the challenges available in Creatory where you will have the opportunity to obtain a token of appreciation available for the related challenges in accordance with the applicable terms and conditions and you are not allowed to participate in Hypes by using more than one User Account simultaneously. You will only be entitled to a token of appreciation if the party publishing the challenge has confirmed that you have completed the challenge correctly and accurately. Any confirmed token of appreciation cannot be canceled or refunded.</li>
              <li><strong>Subscription,</strong> is the service that allows content creators to sell exclusive and limited content. Content creators can define their own subscription levels, benefits and prices for each level.</li>
            </ol>
          </li>
          <li type="A"><strong>Fees.</strong> In return for the provision of Services to you, Creatory shall be entitled to charge or invoice you with the relevant Fee and/or deduct the relevant Fee directly from your Creatory balance as follows:
          <ol className="parens-around">
            <li>In relation to Tipping, Creatory will deduct Service Fee of 2,5% (two point five percent) percent upfront of any donations made to you through the Platform. the illustration is as follows:
            <br>
            <strong>Tipping</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.50.000</strong>
            <br>
            <strong>Service Fee</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: 2.5%</strong>
            <br>
            <strong>Your Balance</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.48.750</strong>
            </li>
            <li>In relation to Hypes, Creatory will deduct Service Fee of 10% (ten percent) percent upfront of any token of appreciation that you are entitled to in accordance with the relevant challenges. the illustration is as follows:
            <br>
            <strong>Token of Rewards</strong>&nbsp;&nbsp;
            <strong>: Rp.1.000.000</strong>
            <br>
            <strong>Service Fee</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: 10%</strong>
            <br>
            <strong>Your Balance</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.900.000</strong>
            </li>
            <li>In relation to Subscription, Creatory will deduct Service Fee of 20% (twenty percent) percent upfront of any token of appreciation that you are entitled to in accordance with the relevant collaborations. the illustration is as follows:
            <br>
            <strong>Token of Appreciation</strong>&nbsp;&nbsp;
            <strong>: Rp.1.000.000</strong>
            <br>
            <strong>Service Fee</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: 20%</strong>
            <br>
            <strong>Your Balance</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.800.000</strong>
            </li>
            <li>Based on User's request, Creatory will send your requested amount of balance no later than 3 days from the request date and Creatory will charge a remittance service fee as follows:
              <ol className='lower-roman parens-around'>
                <li>If the balance is remitted to your bank account: [Rp.4,500] per remittance (for any amount).</li>
                <li>If the balance is remitted to your OVO account: [Rp.1,000] per remittance (for any amount).</li>
              </ol>
            </li>
          </ol>
      </li>
    </ol>
      <li><strong>Balance</strong>
      <ol>
      <li type="A"><strong>Balance.</strong> You authorize Creatory (or its designated providers) to (a) collect, calculate or process donation and/or token of appreciation under these Terms; and (b) transfer your Creatory balance (less any applicable fees) according to your request.</li>
      <li type="A"><strong>No Responsibility.</strong> Creatory (or its designated providers) will have no responsibility with respect to the legality of transactions that you do or occur between you and other Users through the Platform.</li>
      <li type="A"><strong>Remedies and No Waiver.</strong> Creatory may (or may request its designated providers to) delay, suspend or cancel any of your Creatory balance transfer if Creatory reasonably concludes that your actions and/or performance in connection with these Terms are likely to result, or have resulted, in a breach of any provision of these Terms and/or any failure to perform any due obligation under these Terms, any disputes, chargebacks or other third party claims (including other User’s claims), or if there are any sums owed by you to Creatory, Creatory may withhold your Creatory balance transfer for (whichever is longer): (a) 90 days; (b) the completion of any investigation regarding your actions or performance; or (c) the resolution of any dispute. Where a balance transfer is cancelled, such balance shall be forfeited to Creatory and you give up all claim and right to such balance. Any balance transfer made by Creatory to you will not in any way be considered as a waiver of Creatory's rights under these Terms.</li>
      <li type="A"><strong>Income Tax.</strong> You agree to pay your income tax and to self-report your income tax in accordance with tax regulations on any balance obtained by you from using the Services. You will defend, indemnify and hold Creatory and Creatory Affiliates, and our respective officers, employees, directors, contractors, partners, agents, subcontractors and representatives, harmless from, any and all claims arising out of, or related to your negligence or violation to your taxation obligations.</li>
      </ol>
      <li><strong>Access to Services</strong>
      <ol>
      <li type="A"><strong>Access to User Account.</strong> You are responsible for ensuring that you are the only person that can operate your User Account and will not disclose confidential and sensitive information related to your User Account. Creatory will deem all actions taken in your User Account are truly carried out by you unless you have given Creatory advance written report of any misuse or unauthorised access to your User Account. Creatory will not be responsible for any losses you may have due to such misuse or unauthorised access which are not the result of Creatory’s mistakes.</li>
      <li type="A"><strong>Accuracy of User Account Information.</strong> You are responsible for ensuring that all information (identity and including email and bank account details) provided or made available by you on User Account is accurate and complete at all times. You shall bear all consequences ensuing from any incorrect information provided and Creatory shall have no responsibility to verify any information, take any steps to rectify the situation, or remit any payment to you.</li>
      </ol>
      <li><strong>Creatory Rights</strong>
      <ol>
      <li type="A"><strong>Creatory Rights.</strong> If the content or your behavior on the Platform do not comply with these Terms, applicable laws or for any reasonable cause, Creatory may at any time (a) delay or suspend listing of, or to refuse to list, or to de-list any or all content; (b) reset your User Account name; (c) withhold (or request its designated providers to withhold) your Creatory balance, and such balance may be applied towards refunds to entitled parties, and any costs, fees, penalties or fines imposed by any competent authorities; and/or (d) allow other User to cancel an activity of certain Services to you because the Platform or the Services are unavailable following the commencement of a transaction or activity.</li>
      <li type="A"><strong>Deactivation of User Account.</strong> Creatory may deactivate your User Account with immediate effect and withhold all of your Creatory balance if (a) you breach any obligations under these Terms or applicable laws relating to Intellectual Property Rights; (b) you publish, showing, distribute, and/or provide prohibited content and/or activities based on the applicable laws; (c) breach any applicable laws; and/or (d) you use the Platform or the Services in a fraudulent manner.</li>
      <li type="A"><strong>Compliance Purposes.</strong> For the purposes of prevention of fraud, compliance with applicable laws or these Terms, or other commercially reasonable reasons, Creatory may impose terms and conditions, certain value or transaction limits on your User Account.</li>
      <li type="A"><strong>Third Party Service Providers.</strong> Creatory may work with and/or use the services of its designated providers or other third-party service providers in connection with the Services.</li>
      </ol>
      <li><strong>User Representations, Warranties and Undertakings</strong>
      <ol>
      <li type="A"><strong>General undertakings.</strong> By using the Platform or the Services provided by Creatory or Creatory Affiliate, you undertake, represent and warrant that:
      <ol className="parens-around">
      <li>you shall (i) comply with all applicable laws and regulations, including all anti-bribery, anti-corruption and tax laws relating to your activities; (ii) be responsible for and pay all taxes and other charges arising out of or associated with these Terms; and (iii) obtain all necessary rights, licences, permits, or approvals required for becoming a User in the Platform and/or using the Services;</li>
      <li>you shall comply with these Terms and any additional terms;</li>
      <li>you are not a minor and have full power, capacity and authority to enter into and perform your obligations under the Terms; and (ii) any information provided or made available by you is at all times accurate and complete;</li>
      <li>you shall include all information and supporting documents required by applicable law and ensure that any information provided under these Terms is accurate, current, complete and is not misleading;</li>
      <li>you shall fulfil all activities related with the Services at their stated terms and be responsible for any error in the implementation of your activities;</li>
      <li>you shall not infringe any Intellectual Property Rights;</li>
      <li>you shall not post, display or disclose any materials which infringe these Terms or applicable laws;</li>
      <li>you shall not access content and information that concerns any party other than you, transmit unsolicited communications, interfere with the proper working of the Platform, transmit any viruses, Trojan horses, or other harmful code, or attempt to bypass any mechanism used to detect or prevent such activities;</li>
      <li>you shall not intentionally expose Creatory and/or Creatory Affiliates, and our respective officers, employees, directors, contractors, partners, agents, subcontractors, representatives etc., to undue risk or otherwise engage in activities that Creatory determines to be harmful to Creatory and Creatory Affiliates' operations, reputation, or goodwill; and</li>
      <li>ensure that the content or activity you provide to other Users is at least as valuable as the content or activity offered by you outside the Platform on other online channels for the same content or activity in like or lesser quantities.</li>
      </ol>
      </li>
      <li type="A"><strong>Undertakings in relation to User content or activity.</strong> You acknowledge, undertake, represent and warrant that:
      <ol className="parens-around">
     <li>you are the owner or have lawful rights with respect to the use of Intellectual Property Rights concerning your content or activity and you are not aware of any claims made by any third party with regard to any alleged or actual Intellectual Property Right infringement or other claim, demand or action resulting from your content or activity;</li>
     <li>your content or activity is not prohibited and comply with applicable laws (including all minimum age);</li>
     <li>you will not use any intellectual property belonging to Creatory and/or Creatory Affiliates without Creatory's prior approval in writing (including participating in actions such as reverse engineering, reverse compiling or otherwise deriving the underlying source code or structure or sequence of any Creatory solution or technologies, deleting or altering author attributes or copyright notices, and/or fail to obtain all required permissions when using the Platform to receive, upload, display, distribute, or execute programs or perform other works protected by intellectual-property laws);</li>
     <li>you grant Creatory and Creatory Affiliates a royalty-free, non-exclusive, and worldwide right and license to use, reproduce, display, modify, and re-format any and all of the User content provided by the you for the purpose of operation of the Platform or performance of the Services under these Terms;</li>
     <li>Creatory may determine the use and placement of User content, the structure, appearance, design, functionality and all other aspects of the Platform and/or the Services;</li>
     <li>these Terms confer on you no rights of ownership or title, license, or other Intellectual Property Rights in any tangible or intangible property, including software (e.g. the Platform and any application programming interface or other software) and data (e.g. performance data and other User data) used, obtained or created under these Terms. If such rights were nevertheless to have accrued to it for any reason whatsoever, you assign, dispose or otherwise transfer (and effect the transfer of) the full and exclusive ownership of all such rights to Creatory or any other party designated by Creatory, free of charge, or for a nominal fee; and</li>
     <li>nothing herein contained will be deemed to limit or restrict our or any third party's rights to assert claims for violation of any Intellectual Property Rights against you.</li>
      </ol>
      </li>
      </ol>
      <li><strong>Confidential Information</strong>
      <ol>
      <li type="A"><strong>Restriction on disclosure.</strong> The recipient of any Confidential Information will not disclose that Confidential Information, except to Affiliates, employees, and/or agents who need to know it and who have agreed in writing to keep it confidential. The recipient will ensure that those people and entities use Confidential Information only to exercise rights and fulfil obligations under the Terms and keep the Confidential Information confidential. The recipient may also disclose Confidential Information when required by law after giving the discloser reasonable notice and the opportunity to seek confidential treatment, a protective order or similar remedies or relief prior to disclosure.</li>
      <li type="A"><strong>Survival of obligations.</strong> The rights and obligations of the parties under this Clause shall survive the termination of the Terms.</li>
      </ol>
      </li>
      <li><strong>Personal Data and User Information</strong>
      <ol>
      <li type="A"><strong>Use and processing of Personal Data.</strong> You undertake, represent and warrant that you will use and process Personal Data (in particular, the Personal Data of other Users):
      <ol className="parens-around">
        <li>only for the purpose of the execution of these Terms and not disclose it to third parties;</li>
        <li>in accordance with the requirements under the applicable personal data protection laws;</li>
        <li>in a manner that ensures Creatory remains in compliance with the requirement under the applicable personal data protection laws; and</li>
        <li>you will not sell, assign, license, publish, lease or otherwise commercially exploit any such information or utilize such information in any manner for your own benefit or carry out any data mining, data compilation or data extraction for the purposes of statistical or trade analysis or otherwise.</li>
        <li>You further warrant that you have implemented sufficient security measures to ensure that the Personal Data is securely kept and maintained as required by the applicable personal data protection laws and you agree to be subject to the necessary audits undertaken by Creatory to ensure compliance of the above warranties and to immediately inform Creatory of any Personal Data incident upon becoming aware of the same.</li>
      </ol>
      </li>
      <li type="A"><strong>Rights to Information.</strong> Creatory will own all information regarding Users, activity, and the supply of the Services, including balance transfer, Services Fees, etc. and Creatory will not be liable to pay any royalties or fees to you in connection with the use of any such information.</li>
      </ol>
      <li><strong>Liability – Limitations & Indemnification</strong>
      <ol>
      <li type="A"><strong>No liability for inaccuracies or errors.</strong> The Platform and the Services are provided on an "as is" basis. Any information and any materials provided by or through the Platform and the Services may contain errors and Creatory and Creatory Affiliates expressly exclude liability for any such errors to the fullest extent permitted by applicable laws. Any link found on the Platform is provided for your convenience and for further information. It does not signify that Creatory endorses the contents thereof and Creatory has no responsibility for the content of external links.</li>
      <li type="A"><strong>No liability for unavailability.</strong> You acknowledge that the availability of the Services or Platform is subject to:
      <ol className="parens-around">
        <li>availability of resources, including resources under the control of Creatory and availability of a suitable network infrastructure;</li>
        <li>geographic and technical capability of communication networks and other systems;</li>
        <li>provisioning time that may be required by Creatory to provide the Services; and</li>
        <li>you meeting the technical requirements for accessing the Services and/or the Platform from time to time.</li>
      </ol>
      </li>
      <li type="A"><strong>No warranties.</strong> Except as expressly provided for in the Terms, Creatory makes no other representations or warranties of any kind, express or implied, including: (a) implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement; (b) that the Platform or the Services will meet your requirements, will always be available, accessible, uninterrupted, timely, secure, or operate without error; (c) that the information, content, materials, or activities included on the Platform will be as represented by Creatory or that Creatory or the User will perform as promised; (d) any implied warranty arising from course of dealing or activities; and (e) any obligation, liability, right, claim, or remedy in tort, unless arising from acts of fraud, gross negligence or wilful misconduct by Creatory.</li>
      <li type="A"><strong>Correction of documents or content.</strong> Any typographical clerical or other error or omission in any User content or other document on the part of Creatory shall be subject to correction without any liability for Creatory.</li>
      <li type="A"><strong>Indemnity.</strong> You will defend, indemnify and hold Creatory and Creatory Affiliates, and our respective officers, employees, directors, contractors, partners, agents, subcontractors and representatives, harmless from any and all Claims arising out of, or related to:
      <ol className="parens-around">
      <li>any actual or alleged breach of your undertakings, representations, warranties, or obligations set forth in the Terms;</li>
      <li>any incorrect, misleading, or erroneous information provided to Creatory or any third party in connection with the Services;</li>
      <li>any tax compliance costs or tax liability incurred by Creatory or Creatory Affiliates in connection with your activities, arising out of your non-compliance with the applicable tax laws or Creatory or Creatory Affiliates being deemed as your tax agent; or</li>
      <li>Your own content or activity, any actual or alleged infringement of any Intellectual Property Rights by your content or activity, or your taxes obligation. If at any time Creatory reasonably determines that any indemnified claim might adversely affect Creatory, Creatory may take control of the defences at your expense. You may not consent to the entry of any judgment or enter into any settlement of a claim against Creatory without the prior consent by Creatory in writing, which consent may not be unreasonably withheld.</li>
      <li>Creatory’s liability. Creatory will not be held liable for any damages of any kind, including direct, indirect, incidental, punitive, and consequential, arising out of or in connection with the Terms, the Platform, the Services (including inability to use the Platform or the Services), provided that Creatory will compensate you for any direct damages resulting exclusively, or primarily from Creatory's fraud, gross negligence or wilful misconduct.</li>
      <li>Limitation of liability. If Creatory is nevertheless found liable, to the fullest extent permitted by applicable laws, and notwithstanding any other provision of these Terms, the aggregate liability of Creatory and Creatory's Affiliates and our respective officers, employees, directors, contractors, partners, agents, subcontractors and representatives, and any of them, to you and anyone claiming by or through you, for all Claims resulting from or in any way related to the Terms shall not exceed fifteen million Indonesian Rupiah (Rp.15.000.000). It is intended that this limitation apply to any and all liability or cause of action however alleged or arising, unless otherwise prohibited by applicable laws.</li>
      </ol>
      </li>
      </ol>
      </li>
      <li><strong>Termination</strong>
      <ol>
      <li type="A"><strong>Creatory's termination right.</strong> Creatory may unilaterally and immediately terminate these Terms and restrict your use of the Services upon the occurrence of any of the following:
      <ol className="parens-around">
      <li>you being in breach of any provision of the Terms and failing to remedy the same within 14 days from being so notified;</li>
      <li>you being in breach of any applicable laws;</li>
      <li>you being adjudicated a bankrupt or have any similar action taken against you in any jurisdiction;</li>
      <li>the issuance of a judicial management or administrative order in relation to you, or the appointment of a receiver over, or an encumbrance taking possession of, or the sale of, your assets;</li>
      <li>you making an arrangement or composition with your creditors generally or applying to a court of competent jurisdiction for protection from its creditors; or</li>
      <li>you ceasing or threatening to cease to carry on activity on the Platform.</li>
      </ol>
      </li>
      <li type="A"><strong>Consequences of termination.</strong> Upon termination of these Terms, you will notify Creatory of all your accepted activities which have yet to be performed. Creatory shall have the discretion to cancel any pending activities which has not been performed by you and will return the token of appreciation that has been remitted into your Creatory balance to the entitled User. Creatory will close your User Account and delete all your data, information and content so that you can no longer use the Services.</li>
      <li type="A"><strong>Waiver.</strong> The parties agree to waive Article 1266 of the Indonesian Civil Code to the extent that a court order is required to terminate these Terms.</li>
      <li type="A"><strong>Surviving provisions.</strong> Any provision of the Terms that, by its nature, is meant to survive the term or termination, shall survive such term or termination.</li>
      </ol>
      </li>
      <li><strong>Miscellaneous</strong>
      <ol>
      <li type="A"><strong>Assignment.</strong> You cannot assign, transfer or subcontract all or part of your rights and/or obligations deriving from the Terms, without the prior written consent of Creatory. Creatory may assign, transfer or subcontract all or part of its rights and/or obligations deriving from the Terms.</li>
      <li type="A"><strong>Independent Contractors.</strong> You and Creatory are independent contractors, and nothing in the Terms will create any partnership, joint venture, agency, franchise, sales representative relationship or exclusivity between the parties. The Terms will not cause the establishment of any relationship of employment between the parties or with any person who provides services to either. You have no authority to make or accept any offers or representations on behalf of Creatory.</li>
      <li type="A"><strong>Severability.</strong> If any provision in these Terms shall be held to be void but would be valid if deleted in part or reduced in application, such provision shall apply with such deletion or modification as may be necessary to make it valid and enforceable.</li>
      <li type="A"><strong>No waiver.</strong> The failure of a party to exercise its rights in case of breach of the Terms by the other party will not be considered as a waiver of its rights under the Terms or under applicable laws.</li>
      <li type="A"><strong>Force Majeure.</strong> No Party will be liable to the other or be deemed to be in breach of the Terms by reason of any delay or failure to perform any of its obligations due to an event of Force Majeure. Upon the occurrence of any event of Force Majeure, Creatory may, at its option, fully or partially suspend delivery/performance of its obligations hereunder while such event or circumstance continues. If any of the events of Force Majeure will continue for a period exceeding one (1) month, Creatory may notify you that it will terminate the Terms or its relationship with you with immediate effect.</li>
      <li type="A"><strong>Governing law.</strong> This Terms is governed by and shall be construed in accordance with the laws of Indonesia.</li>
      <li type="A"><strong>Dispute resolution.</strong> Any dispute, controversy, difference or claim arising out of or relating to this Terms, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it, shall be referred to and finally resolved by arbitration in accordance with the Arbitration Rules of the Badan Arbitrase Nasional Indonesia. The seat of arbitration shall be Jakarta. The arbitration proceedings shall be conducted in Indonesian language.</li>
      <li type="A"><strong>Complaint Service. </strong> For any single consumer complaints, you are entitled to contact Directorate of General of Consumer Protection and Trade Order at 0853-1111-1010 (whatsapp).</li>
      <li type="A"><strong>Third Party.</strong> Creatory has also collaborated with other third parties regarding the operation of its web, including but not limited to certain sound feature provider. Creatory and you are bound with the terms of use as described in the following link <a href="https://notificationsounds.com/terms-of-use">https://notificationsounds.com/terms-of-use</a></li>
      </li>
      </li>
      </ol>
    `,
  },
  {
    lang: "id",
    content: `
    Selamat datang di situs web Creatory dan/atau aplikasi seluler Creatory. Silahkan membaca Ketentuan Layanan ini (termasuk seluruh Lampirannya) ("<strong>Ketentuan</strong>") dengan seksama. Ketentuan ini mengatur penggunaan dan akses Anda pada Platform sebagai pengguna dan disepakati antara Anda ("<strong>Pengguna</strong>", "<strong>Anda</strong>", "<strong>milik Anda</strong>") and <strong>Creatory</strong> pada tanggal Anda menekan tombol "<strong>Saya Setuju</strong>" atau tombol yang serupa lainnya untuk menyelesaikan proses pendaftaran daring Anda untuk menjadi Pengguna pada Platform ("<strong>Tanggal Berlaku</strong>").
    <br><br>
    <strong>DENGAN MENDAFTAR PADA DAN/ATAU MENGGUNAKAN LAYANAN CREATORY, ANDA SETUJU UNTUK TERIKAT PADA KETENTUAN LAYANAN INI, DAN SEMUA KEBIJAKAN CREATORY YANG DAPAT DILIHAT DI <a href="HTTPS://CREATORY.ID/TOS">HTTPS://CREATORY.ID/TOS</a> DAN <a href="HTTPS://CREATORY.ID/POLICY">HTTPS://CREATORY.ID/POLICY</a> DAN DIMASUKKAN BERDASARKAN RUJUKAN.</strong>
    <br><br>
    Creatory dapat mengubah salah satu dari Ketentuan ini dan setiap biaya, prosedur dan kebijakan yang mengatur Layanan atau Platform pada saat apa pun dan penggunaan Anda atas Layanan atau Platform secara berkelanjutan akan menjadi persetujuan Anda terhadap perubahan tersebut. Apabila Anda tidak menyetujui perubahan tersebut, Anda harus berhenti menggunakan Layanan dan/atau Platform. Untuk menghindari keraguan, versi yang lebih baru dari Ketentuan akan menggantikan versi yang lebih lama.
    <ol>
      <li><strong>Layanan dan Biaya</strong>
        <ol>
          <li type="A"><strong>Layanan.</strong> Layanan yang diberikan oleh Creatory berdasarkan Ketentuan ini digolongkan sebagai:
            <ol className="parens-around">
              <li><strong>Tipping,</strong> yaitu layanan yang memungkinkan Anda untuk menerima dukungan berupa donasi dari penggemar Anda dan/atau untuk memberikan donasi kepada kreator yang terdaftar pada Creatory sesuai dengan syarat dan ketentuan yang berlaku. Donasi Anda yang sudah diterima oleh kreator tidak dapat dikembalikan.</li>
              <li><strong>Hypes,</strong> yaitu layanan yang memungkinkan Anda untuk berpartisipasi dan menyelesaikan tantangan yang tersedia pada Creatory di mana Anda akan dapat berkesempatan untuk mendapatkan tanda apresiasi yang tersedia bagi tantangan yang terkait tersebut sesuai dengan syarat dan ketentuan yang berlaku dan Anda dilarang untuk mengikuti Hypes dengan menggunakan lebih dari satu Akun Pengguna secara bersamaan. Anda baru akan berhak mendapatkan tanda apresiasi apabila pihak yang mencantumkan tantangan telah mengkonfirmasi bahwa Anda telah menyelesaikan tantangan tersebut secara benar dan akurat. Setiap tanda apresiasi yang telah terkonfirmasi tidak dapat dibatalkan atau kembalikan.</li>
              <li><strong>Subscription,</strong> yaitu layanan yang memungkinkan content creator untuk menjual konten secara eksklusif dan terbatas. Konten kreator dapat menentukan sendiri level subscription, benefit dan harga untuk setiap level.</li>
            </ol>
          </li>
          <li type="A"><strong>Biaya.</strong> Sebagai imbal balik atas pemberian Layanan kepada Anda, Creatory berhak membebankan atau menagih Biaya yang terkait dan/atau memotong Biaya yang terkait langsung dari saldo Creatory Anda sebagai berikut:
          <ol className="parens-around">
            <li>Sehubungan dengan Tipping, Creatory akan langsung melakukan pemotongan Biaya Layanan di depan sebesar 2,5% (dua setengah) persen dari setiap donasi yang diberikan kepada Anda melalui Platform. Berikut adalah ilustrasinya:
            <br>
            <strong>Tipping</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.50.000</strong>
            <br>
            <strong>Biaya Layanan</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: 2.5%</strong>
            <br>
            <strong>Saldo Anda</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.48.750</strong>
            </li>
            <li>Sehubungan dengan Hypes, Creatory akan langsung melakukan pemotongan Biaya Layanan di depan sebesar 10% (sepuluh) dari setiap tanda apresiasi yang berhak Anda dapatkan sesuai dengan tantangan yang terkait. Berikut adalah ilustrasinya:
            <br>
            <strong>Tanda penghargaan</strong>&nbsp;&nbsp;
            <strong>: Rp.1.000.000</strong>
            <br>
            <strong>Biaya Layanan</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: 10%</strong>
            <br>
            <strong>Saldo Anda</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.900.000</strong>
            </li>
            <li>Sehubungan dengan Subscription, Creatory akan langsung melakukan pemotongan Biaya Layanan di depan sebesar 20% (dua puluh) dari setiap tanda apresiasi yang berhak Anda dapatkan sesuai dengan kolaborasi yang terkait. Berikut adalah ilustrasinya:
            <br>
            <strong>Tanda apresiasi</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.1.000.000</strong>
            <br>
            <strong>Biaya Layanan</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: 20%</strong>
            <br>
            <strong>Saldo Anda</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>: Rp.800.000</strong>
            </li>
            <li>Sesuai dengan permintaan Pengguna, Creatory akan mengirimkan jumlah saldo permintaan Anda paling lambat 3 hari sejak tanggal permintaan dan Creatory akan mengenakan biaya layanan pengiriman sebagai berikut:
              <ol className='lower-roman parens-around'>
                <li>Apabila saldo dikirimkan ke rekening bank Anda: [Rp.4.500] per pengiriman (untuk jumlah berapapun).</li>
                <li>Apabila saldo dikirimkan ke akun OVO Anda: [Rp.1.000] per pengiriman (untuk jumlah berapapun).</li>
              </ol>
            </li>
          </ol>
      </li>
    </ol>
      <li><strong>Saldo</strong>
      <ol>
      <li type="A"><strong>Saldo.</strong> Anda mengizinkan Creatory (atau para penyedia layanan yang ditunjuk olehnya) untuk (a) mengumpulkan, menghitung, atau memproses donasi dan/atau tanda apresiasi berdasarkan Ketentuan ini dan; (b) mengirimkan saldo Creatory Anda (dengan dikurangi biaya yang berlaku) sesuai dengan permintaan Anda.</li>
      <li type="A"><strong>Tidak ada tanggung jawab.</strong> Creatory (atau penyedia layanan yang ditunjuk olehnya) tidak memiliki tanggung jawab apapun sehubungan dengan kesesuaian dengan hukum dari transaksi-transaksi yang dilakukan oleh Anda atau antara Anda dengan Pengguna lainnya melalui Platform.</li>
      <li type="A"><strong>Pemulihan dan Tidak Ada Pengesampingan.</strong> Creatory dapat (atau dapat meminta para penyedia layanan yang ditunjuk olehnya untuk) menunda, menangguhkan atau membatalkan pengiriman saldo Creatory Anda apabila menurut pendapat wajar Creatory, tindakan Anda sehubungan dengan Ketentuan ini kemungkinan besar menimbulkan, atau telah menimbulkan, adanya pelanggaran terhadap suatu ketentuan dalam Ketentuan ini atau dan/atau kegagalan dalam melaksanakan suatu kewajiban yang harus dilaksanakan berdasarkan Ketentuan ini, setiap sengketa, tagihan balik atau tuntutan pihak ketiga (termasuk tuntutan Pengguna lainnya), atau apabila ada jumlah yang harus dibayar oleh Anda kepada Creatory, Creatory dapat menahan pengiriman saldo Creatory Anda selama (yang mana lebih lama): (a) 90 hari; (b) penyelesaian penyelidikan terkait dengan tindakan Anda; atau (c) penyelesaian sengketa. Apabila suatu pengiriman saldo dibatalkan, saldo tersebut akan diserahkan kepada Creatory dan Anda akan melepaskan hak menuntut dan hak atas uang tersebut. Setiap pengiriman saldo yang dilakukan oleh Creatory kepada Anda tidak akan, dengan cara apa pun, dianggap sebagai pengesampingan dari hak-hak Creatory berdasarkan Ketentuan ini.</li>
      <li type="A"><strong>Pajak Penghasilan.</strong> Anda setuju untuk membayar pajak penghasilan Anda dan melaporkan pajak penghasilan Anda secara mandiri sesuai dengan peraturan perpajakan atas saldo apapun yang Anda peroleh dari penggunaan Layanan. Anda akan membela, memberikan ganti rugi kepada dan membebaskan Creatory dan Afiliasi Creatory dan masing-masing pejabat, karyawan, direktur, kontraktor, mitra, agen, subkontraktor dan perwakilan kami terhadap, setiap dan semua tuntutan yang timbul dari, atau yang berkaitan dengan kelalaian atau pelanggaran kewajiban perpajakan Anda tersebut.</li>
      </ol>
      <li><strong>Akses ke Layanan</strong>
      <ol>
      <li type="A"><strong>Akses ke Akun Pengguna.</strong>  Anda bertanggung jawab untuk memastikan bahwa hanya Anda yang dapat mengoperasikan Akun Pengguna Anda dan tidak akan mengungkapkan informasi sensitif dan rahasia terkait Akun Pengguna Anda. Creatory akan menganggap semua tindakan yang diambil dalam Akun Pengguna Anda adalah benar dilakukan oleh Anda kecuali Anda telah memberikan laporan tertulis sebelumnya kepada Creatory tentang penyalahgunaan atau akses tidak sah ke Akun Pengguna Anda. Creatory tidak akan bertanggung jawab atas kerugian apa pun yang mungkin Anda alami karena penyalahgunaan atau akses tidak sah tersebut yang bukan merupakan akibat dari kesalahan Creatory.</li>
      <li type="A"><strong>Keakuratan Informasi Akun Pengguna.</strong> Anda bertanggung jawab untuk memastikan bahwa seluruh informasi (identitas serta termasuk keterangan terperinci mengenai surel dan rekening bank) yang diberikan atau disediakan oleh Anda mengenai Akun Pengguna selalu akurat dan lengkap. Anda akan menanggung semua konsekuensi yang diakibatkan oleh informasi yang tidak benar yang diberikan dan Creatory tidak memiliki tanggung jawab apa pun untuk memverifikasi informasi apa pun, mengambil langkah apa pun untuk memulihkan keadaan, atau melakukan pembayaran apapun kepada Anda.</li>
      </ol>
      <li><strong>Hak-Hak Creatory</strong>
      <ol>
      <li type="A"><strong>Hak-Hak Creatory.</strong> Apabila konten atau perbuatan Anda di Platform tidak sesuai dengan Ketentuan ini, hukum yang berlaku atau dengan alasan yang masuk akal, Creatory dapat pada setiap waktu (a) menunda atau menangguhkan pencantuman, atau menolak untuk mencantumkan, atau mencabut suatu atau semua konten Anda; (b) mengatur ulang Akun Pengguna Anda; (c) memotong (atau meminta para penyedia layanan yang ditunjuk olehnya untuk memotong) saldo Creatory Anda, dan saldo tersebut dapat digunakan untuk mengembalikan uang kepada pihak yang berhak mendapatkannya, dan setiap beban, biaya, penalti atau denda yang dikenakan oleh otoritas yang berwenang; dan/atau (d) mengizinkan Pengguna lainnya untuk membatalkan suatu kegiatan terkait Layanan tertentu terhadap Anda karena Platform atau Layanan tidak tersedia setelah dimulainya transaksi atau kegiatan.</li>
      <li type="A"><strong>Penonaktifan Akun Pengguna.</strong> Creatory dapat menonaktifkan Akun Pengguna secara langsung dan menahan semua saldo Creatory Anda apabila (a) Anda melanggar kewajiban-kewajiban berdasarkan Ketentuan ini atau hukum yang berlaku sehubungan dengan Hak Kekayaan Intelektual; (b) Anda mempublikasikan, menayangkan, mendistribusikan, dan/atau menyediakan konten dan/atau tindakan yang dilarang berdasarkan hukum yang berlaku; (c) melanggar hukum yang berlaku; dan/atau (d) Anda menggunakan Platform atau Layanan dengan tujuan melakukan penipuan.</li>
      <li type="A"><strong>Kepentingan Kepatuhan.</strong> Untuk kepentingan mencegah penipuan, kepatuhan pada hukum yang berlaku atau Ketentuan ini, atau alasan-alasan yang wajar secara komersial lainnya, Creatory dapat menetapkan syarat dan ketentuan, batas nilai atau transaksi tertentu pada Akun Pengguna Anda.</li>
      <li type="A"><strong>Pemberi Layanan Pihak Ketiga.</strong> Creatory dapat bekerja sama dengan dan/atau menggunakan layanan dari para penyedia layanan yang ditunjuk olehnya atau penyedia layanan pihak ketiga sehubungan dengan Layanan.</li>
      </ol>
      <li><strong>Pernyataan, Jaminan dan Kesanggupan Pengguna</strong>
      <ol>
      <li type="A"><strong>Kesanggupan umum.</strong> Dengan menggunakan Platform atau Layanan yang disediakan oleh Creatory atau Afiliasi Creatory, Anda menyanggupi, menyatakan dan menjamin bahwa:
      <ol className="parens-around">
      <li>Anda akan (i) mematuhi setiap peraturan perundang-undangan yang berlaku, termasuk setiap hukum anti-suap, anti-korupsi dan pajak terkait dengan kegiatan Anda; (ii) bertanggung jawab atas dan membayar setiap pajak dan biaya lainnya yang timbul dari atau yang berkaitan dengan Ketentuan ini; dan (iii) memperoleh semua hak, perizinan, izin atau persetujuan yang diperlukan untuk menjadi Pengguna dalam Platform dan/atau menggunakan Layanan.</li>
      <li>Anda akan mematuhi Ketentuan ini dan ketentuan tambahan lainnya;</li>
      <li>Anda bukan individu di bawah umur dan memiliki kuasa, kapasitas dan kewenangan penuh untuk melaksanakan dan memenuhi kewajiban Anda berdasarkan Ketentuan; dan (ii) informasi apa pun yang diberikan atau disediakan oleh Anda selalu akurat dan lengkap;</li>
      <li>Anda akan memuat semua informasi dan dokumen pendukung yang diwajibkan berdasarkan hukum yang berlaku termasuk dan memastikan bahwa setiap informasi yang diberikan berdasarkan Ketentuan ini bersifat akurat, baru, lengkap dan tidak menyesatkan;</li>
      <li>Anda akan melaksanakan semua kegiatan Anda yang berkaitan dengan Layanan sesuai dengan ketentuan yang telah ditetapkan dan bertanggung jawab atas kesalahan apa pun dalam pelaksanaan kegiatan Anda;</li>
      <li>Anda tidak akan melanggar Hak Kekayaan Intelektual apa pun;</li>
      <li>Anda tidak akan mempublikasikan, menayangkan, mendistribusikan, dan/atau menyediakan konten dan/atau tindakan apa pun yang melanggar Ketentuan ini atau hukum yang berlaku;</li>
      <li>Anda tidak akan mengakses konten dan informasi mengenai pihak manapun selain Anda, melakukan komunikasi yang tidak diinginkan, mengganggu kinerja yang sepatutnya dari Platform, mengirimkan virus, kuda Troya, atau kode yang berbahaya lainnya, atau berupaya memintaskan suatu mekanisme yang digunakan untuk mendeteksi atau mencegah adanya kegiatan tersebut;</li>
      <li>Anda tidak akan dengan sengaja mengekspos Creatory dan/atau Afiliasi Creatory, dan masing-masing pejabat, karyawan, direktur, kontraktor, mitra, agen, subkontraktor, perwakilan dll. kami, pada risiko yang tidak semestinya atau dengan cara lain terlibat dalam kegiatan yang menurut Creatory, berbahaya bagi kegiatan operasional, reputasi atau muhibah Creatory dan Afiliasi Creatory; dan</li>
      <li>memastikan bahwa konten atau kegiatan yang Anda sediakan kepada Pengguna lain setidaknya sama nilainya dengan konten atau kegiatan yang ditawarkan oleh Anda di luar Platform pada saluran daring lainnya untuk konten atau kegiatan yang sama dalam jumlah yang setara atau kurang.</li>
      </ol>
      </li>
      <li type="A"><strong>Kesanggupan sehubungan dengan konten atau kegiatan Pengguna.</strong> Anda mengakui, menyanggupi, menyatakan dan menjamin bahwa:
      <ol className="parens-around">
     <li>Anda adalah pemilik atau memiliki hak yang sesuai dengan hukum sehubungan dengan penggunaan Hak Kekayaan Intelektual terkait dengan konten atau kegiatan Anda dan Anda tidak mengetahui adanya tuntutan yang diajukan oleh pihak ketiga mana pun terkait dengan suatu dugaan pelanggaran atau pelanggaran yang aktual terhadap Hak Kekayaan Intelektual atau gugatan, tuntutan atau perkara lainnya yang berasal dari konten atau kegiatan Anda;</li>
     <li>konten atau kegiatan Anda tidak dilarang dan mematuhi hukum yang berlaku (termasuk semua persyaratan umur minimum);</li>
     <li>Anda tidak akan menggunakan kekayaan intelektual apa pun yang dimiliki oleh Creatory dan/atau Afiliasi Creatory tanpa persetujuan tertulis sebelumnya dari Creatory (termasuk keikutsertaan dalam tindakan-tindakan seperti rekayasa balik, pengumpulan balik atau dengan cara lain mengambil kode sumber atau struktur atau rangkaian dari solusi atau teknologi Creatory, menghapus atau mengubah atribut atau pemberitahuan hak cipta, dan/atau gagal mendapatkan semua izin yang diperlukan pada saat menggunakan Platform untuk menerima, mengunggah, menampilkan, mendistribusikan, atau menjalankan program atau melakukan pekerjaan lainnya yang dilindungi oleh hukum kekayaan intelektual);</li>
     <li>Anda memberi Creatory dan Afiliasi Creatory suatu hak dan lisensi yang bebas royalti, non-eksklusif dan tersedia di seluruh dunia untuk menggunakan, memperbanyak, menampilkan, mengubah, dan memformat ulang setiap dan semua konten Pengguna yang diberikan oleh Anda untuk kepentingan mengoperasikan Platform atau memberikan Layanan berdasarkan Ketentuan ini;</li>
     <li>Creatory dapat menentukan penggunaan dan penempatan konten Pengguna, dan struktur, tampilan, desain, fungsionalitas dan semua aspek lainnya dari Platform dan/atau Layanan;</li>
     <li>ketentuan ini tidak memberi Anda hak atas kepemilikan atau alas hak, lisensi atau Hak Kekayaan Intelektual atas benda yang berwujud atau tidak berwujud, termasuk perangkat lunak (mis. Platform dan antarmuka pemrograman aplikasi atau perangkat lunak) dan data (mis. data kinerja dan data Pengguna lainnya) yang digunakan, diperoleh atau dibuat berdasarkan Ketentuan ini. Apabila hak tersebut telah diperoleh untuk alasan apa pun, Anda mengalihkan, melepaskan atau dengan cara lain memindahkan (dan melakukan pengalihan atas) kepemilikan penuh dan eksklusif atas semua hak tersebut kepada Creatory atau pihak lain yang ditunjuk oleh Creatory, tanpa dikenakan biaya apa pun, atau untuk biaya nominal; dan</li>
     <li>tidak ada ketentuan apa pun yang dimuat dalam Ketentuan ini yang akan dianggap membatasi atau melarang hak Creatory atau hak pihak ketiga untuk mengajukan tuntutan atas pelanggaran terhadap Hak Kekayaan Intelektual terhadap Anda.</li>
      </ol>
      </li>
      </ol>
      <li><strong>Informasi Rahasia</strong>
      <ol>
      <li type="A"><strong>Pembatasan pengungkapan.</strong> Penerima Informasi Rahasia tidak akan mengungkapkan Informasi Rahasia tersebut, kecuali kepada Afiliasi, karyawan dan/atau agen yang perlu mengetahuinya dan yang telah menyetujui secara tertulis untuk menjaga kerahasiaannya. Penerima informasi akan memastikan bahwa orang dan entitas tersebut menggunakan Informasi Rahasia hanya untuk menggunakan hak dan memenuhi kewajiban berdasarkan Ketentuan dan menjaga kerahasiaan dari Informasi Rahasia. Penerima informasi juga dapat mengungkapkan Informasi Rahasia pada saat diwajibkan berdasarkan hukum setelah menyampaikan pemberitahuan yang wajar kepada pemberi informasi dan memberinya peluang untuk menjaga kerahasiaan informasinya, perlindungan atau pemulihan atau upaya hukum yang serupa untuk pengungkapan tersebut.</li>
      <li type="A"><strong>Kewajiban berkelanjutan.</strong> Hak dan kewajiban para pihak berdasarkan Pasal ini akan tetap berlaku setelah pengakhiran Ketentuan.</li>
      </ol>
      </li>
      <li><strong>Data Pribadi dan Informasi Pengguna</strong>
      <ol>
      <li type="A"><strong>Penggunaan dan pemrosesan Data Pribadi.</strong> Anda menyanggupi, menyatakan dan menjamin bahwa Anda akan menggunakan dan memproses Data Pribadi (khususnya, Data Pribadi Pengguna lainnya):
      <ol className="parens-around">
        <li>hanya untuk kepentingan pelaksanaan Ketentuan dan tidak akan mengungkapkannya kepada para pihak ketiga;</li>
        <li>sesuai dengan persyaratan berdasarkan hukum perlindungan data pribadi yang berlaku;</li>
        <li>dengan cara yang memastikan bahwa Creatory tetap memenuhi persyaratan berdasarkan hukum perlindungan data pribadi yang berlaku; dan</li>
        <li>Anda tidak akan menjual, mengalihkan, melisensikan, mempublikasikan, menyewakan atau dengan cara lain mengeksploitasi secara komersial informasi tersebut atau memanfaatkan informasi tersebut dengan cara apa pun untuk kepentingan Anda sendiri atau melakukan pemerolehan data, pengumpulan data atau pengutipan data untuk kepentingan analisis statistik atau perdagangan atau lain-lain.</li>
        <li>Anda juga menjamin bahwa Anda mengambil langkah-langkah keamanan yang memadai untuk memastikan bahwa Data Pribadi dijaga dan dipertahankan secara aman sebagaimana diwajibkan berdasarkan hukum perlindungan data pribadi yang berlaku dan Anda setuju untuk menjadi subjek audit yang perlu dilakukan oleh Creatory untuk memastikan kepatuhan pada jaminan yang disebutkan di atas dan segera memberitahu Creatory mengenai insiden Data Pribadi apa pun pada waktu mengetahuinya.</li>
      </ol>
      </li>
      <li type="A"><strong>Hak atas Informasi.</strong> Creatory akan memiliki semua informasi mengenai para Pengguna, kegiatan dan pemberian Layanan, termasuk pengiriman saldo, Biaya Layanan, dll. dan Creatory tidak bertanggung jawab untuk membayar royalti atau biaya apapun kepada Anda sehubungan dengan penggunaan informasi tersebut.</li>
      </ol>
      <li><strong>Tanggung Jawab – Pembatasan dan Penggantian Kerugian</strong>
      <ol>
      <li type="A"><strong>Tidak ada tanggung jawab atas ketidakakuratan atau kesalahan.</strong> Platform dan Layanan diberikan "apa adanya". Setiap informasi dan bahan yang diberikan oleh atau melalui Platform dan Layanan mungkin berisi kesalahan dan Creatory dan Afiliasi Creatory secara tegas mengecualikan tanggung jawab atas kesalahan tersebut sepanjang yang mungkin diizinkan berdasarkan hukum yang berlaku. Setiap pranala yang ditampilkan di Platform diberikan untuk kepentingan Anda dan untuk informasi lebih lanjut. Hal tersebut tidak menunjukkan bahwa Creatory mengesahkan isinya dan Creatory tidak memiliki tanggung jawab apa pun atas konten dari pranala eksternal.</li>
      <li type="A"><strong>Tidak ada tanggung jawab atas ketidaktersediaan.</strong> Anda mengakui bahwa ketersediaan Layanan atau Platform tunduk pada:
      <ol className="parens-around">
        <li>ketersediaan sumber daya, termasuk sumber daya yang berada di bawah kendali Creatory dan ketersediaan infrastruktur jaringan yang sesuai;</li>
        <li>kemampuan geografis dan teknis jaringan komunikasi dan sistem lainnya;</li>
        <li>waktu operasional yang mungkin diperlukan bagi Creatory untuk memberikan Layanan; dan</li>
        <li>pemenuhan persyaratan teknis oleh Anda untuk mengakses Layanan dan/atau Platform dari waktu ke waktu.</li>
      </ol>
      </li>
      <li type="A"><strong>Tidak ada jaminan.</strong> Kecuali sebagaimana dinyatakan secara tegas dalam Ketentuan, Creatory tidak membuat pernyataan atau jaminan lain jenis apa pun, yang tersurat atau tersirat, termasuk: (a) jaminan kelayakan untuk diperjualbelikan, kelayakan untuk suatu kepentingan tertentu, alas hak dan ketiadaan pelanggaran yang tersirat; (b) bahwa Platform atau Layanan akan memenuhi persyaratan Anda, akan selalu tersedia, dapat diakses, tidak mengalami gangguan, tepat waktu, aman, atau dapat dioperasikan tanpa ada kesalahan; (c) bahwa informasi, konten, bahan atau kegiatan yang dimasukkan ke Platform adalah sebagaimana dinyatakan oleh Creatory atau sebagaimana yang akan dijanjikan oleh Creatory atau para Pengguna; (d) jaminan yang tersirat yang timbul dari urusan atau kegiatan; dan (e) setiap kewajiban, tanggung jawab, hak, tuntutan atau pemulihan dari perbuatan melawan hukum, kecuali yang timbul dari tindakan penipuan, kelalaian besar atau kesalahan yang disengaja oleh Creatory.</li>
      <li type="A"><strong>Koreksi dokumen atau konten.</strong> Suatu kesalahan berupa salah ketik atau kesalahan atau pengabaian lainnya dalam konten Pengguna atau dokumen lain dari pihak Creatory harus diperbaiki tanpa membebankan tanggung jawab apapun kepada Creatory.</li>
      <li type="A"><strong>Ganti Rugi.</strong> Anda akan membela, memberikan ganti rugi kepada dan membebaskan Creatory dan Afiliasi Creatory dan masing-masing pejabat, karyawan, direktur, kontraktor, mitra, agen, subkontraktor dan perwakilan kami, dari setiap dan semua Tuntutan yang timbul dari, atau yang berkaitan dengan:
      <ol className="parens-around">
      <li>pelanggaran yang aktual atau dugaan pelanggaran terhadap kesanggupan, pernyataan, jaminan, atau kewajiban Anda yang ditetapkan dalam Ketentuan;</li>
      <li>informasi yang tidak benar, menyesatkan atau salah yang diberikan kepada Creatory atau pihak ketiga mana pun sehubungan dengan Layanan;</li>
      <li>biaya kepatuhan pajak atau kewajiban pajak yang ditanggung oleh Creatory atau Afiliasi Creatory sehubungan dengan kegiatan Anda, yang timbul dari ketidakpatuhan Anda pada hukum pajak yang berlaku atau karena Creatory atau Afiliasi Creatory dianggap sebagai agen pajak Anda; atau</li>
      <li>Konten atau kegiatan Anda, pelanggaran yang aktual atau dugaan pelanggaran terhadap Hak Kekayaan Intelektual yang diakibatkan oleh konten atau kegiatan Anda, atau kewajiban pajak Anda. Apabila sewaktu-waktu Creatory sewajarnya menetapkan bahwa suatu tuntutan atas ganti rugi dapat memiliki dampak yang merugikan secara material terhadap Creatory, Creatory dapat melakukan pembelaan terhadap hal tersebut atas biaya Anda. Anda tidak boleh mengizinkan ditetapkannya keputusan atau mengatasi tuntutan terhadap Creatory tanpa persetujuan tertulis dari Creatory, yang persetujuan tersebut tidak boleh ditahan secara tidak wajar.</li>
      <li><strong>Tanggung jawab Creatory.</strong Creatory tidak akan bertanggung jawab atas ganti rugi apa pun, termasuk ganti rugi langsung, tidak langsung, insidental, bersifat hukuman dan konsekuensial yang timbul dari atau sehubungan dengan Ketentuan, Platform atau Layanan (termasuk ketidakmampuan untuk menggunakan Platform atau Layanan), dengan ketentuan bahwa Creatory akan memberikan ganti rugi kepada Anda atas ganti rugi langsung yang timbul secara eksklusif, atau sebagian besar dari penipuan, kelalaian besar atau kesalahan yang disengaja oleh Creatory.</li>
      <li><strong>Pembatasan tanggung jawab.</strong Apabila Creatory harus bertanggung jawab, sepanjang diizinkan berdasarkan hukum yang berlaku, dan terlepas dari ketentuan lainnya dalam Ketentuan ini, keseluruhan tanggung jawab Creatory dan Afiliasi Creatory dan masing-masing pejabat, karyawan, direktur, kontraktor, mitra, agen, subkontraktor dan perwakilan kami, dan salah satu dari mereka, kepada Anda dan siapa pun yang mengajukan bersama atau melalui Anda, seluruh Tuntutan yang timbul dari atau yang dengan cara apapun berkaitan dengan Ketentuan tidak akan melebihi lima belas juta Rupiah (Rp.15.000.000). Telah diartikan bahwa pembatasan ini berlaku terhadap setiap dan semua tanggung jawab atau dasar tuntutan bagaimanapun yang dinyatakan atau ditimbulkan, kecuali dilarang dengan cara lain berdasarkan hukum yang berlaku.</li>
      </ol>
      </li>
      </ol>
      </li>
      <li><strong>Pengakhiran</strong>
      <ol>
      <li type="A"><strong>Hak pengakhiran oleh Creatory.</strong> Creatory dapat mengakhiri Ketentuan ini secara sepihak dan secara langsung serta melarang penggunaan oleh Anda atas Layanan pada saat terjadinya salah satu dari hal-hal berikut ini:
      <ol className="parens-around">
      <li>Anda melanggar suatu ketentuan dalam Ketentuan dan gagal memulihkannya dalam waktu 14 hari sejak diberitahukan;</li>
      <li>Anda melanggar hukum yang berlaku;</li>
      <li>Anda dinyatakan pailit atau ada tindakan yang serupa yang diambil terhadap Anda di yurisdiksi mana pun;</li>
      <li>dikeluarkannya keputusan manajemen yudisial atau administratif sehubungan dengan Anda, atau penunjukan kurator atas, atau pembebanan yang menguasai, atau menjual, aset-aset Anda;</li>
      <li>pengaturan yang diadakan oleh Anda atau perdamaian Anda bersama para kreditur Anda secara umum atau pengajuan perlindungan ke pengadilan yang memiliki kompetensi dari yurisdiksi terkait dari para krediturnya; atau</li>
      <li>Anda tidak lagi atau mengancam untuk tidak lagi menjalankan kegiatan Anda pada Platform.</li>
      </ol>
      </li>
      <li type="A"><strong>Akibat pengakhiran.</strong> Pada waktu pengakhiran Ketentuan ini, Anda harus memberi tahu Creatory mengenai semua kegiatan yang Anda terima yang belum sempat dilaksanakan. Creatory memiliki diskresi untuk membatalkan kegiatan yang belum sempat dilaksanakan oleh Anda dan akan mengembalikan tanda apresiasi yang sudah masuk ke dalam saldo Creatory Anda kepada Pengguna yang berhak atas itu. Creatory akan melakukan penutupan Akun Pengguna Anda dan menghapus semua data, informasi, dan konten Anda sehingga Anda tidak bisa lagi menggunakan Layanan.</li>
      <li type="A"><strong>Pengesampingan.</strong> Para pihak sepakat untuk mengesampingkan Pasal 1266 dari Kitab Undang-Undang Hukum Perdata sejauh suatu keputusan pengadilan diperlukan untuk mengakhiri Ketentuan ini.</li>
      <li type="A"><strong>Ketentuan yang tetap berlaku.</strong> Suatu ketentuan dari Ketentuan yang, menurut sifatnya, dimaksudkan untuk tetap berlaku setelah jangka waktu atau pengakhiran Ketentuan ini, akan tetap berlaku setelah jangka waktu atau pengakhiran Ketentuan ini.</li>
      </ol>
      </li>
      <li><strong>Lain-Lain</strong>
      <ol>
      <li type="A"><strong>Pengalihan.</strong> Anda tidak boleh mengalihkan, memindahkan atau melakukan subkontrak atas seluruh atau suatu bagian dari hak dan/atau kewajiban yang ditetapkan dalam Ketentuan ini, tanpa persetujuan tertulis sebelumnya dari Creatory. Creatory dapat mengalihkan, memindahkan atau melakukan subkontrak atas seluruh atau suatu bagian dari hak dan/atau kewajiban yang ditetapkan dalam Ketentuan ini.</li>
      <li type="A"><strong>Kontraktor Independen.</strong> Anda dan Creatory merupakan kontraktor independen, dan tidak ada ketentuan apa pun dalam Ketentuan ini yang akan membentuk suatu kemitraan, usaha patungan, agensi, waralaba, hubungan perwakilan atau agen penjualan antara para pihak. Ketentuan ini tidak akan menyebabkan dibentuknya suatu hubungan antara pemberi kerja dan pekerja di antara para pihak atau dengan pribadi mana pun yang memberikan layanan kepada salah satu pihak. Anda tidak memiliki kewenangan apa pun untuk membuat atau menerima penawaran atau pernyataan apa pun atas nama Creatory.</li>
      <li type="A"><strong>Keterpisahan.</strong> Apabila suatu ketentuan dalam Ketentuan ini dinyatakan batal tetapi tetap sah apabila dihapus sebagian atau dikurangi penerapannya, ketentuan tersebut akan berlaku dengan penghapusan atau perubahan sebagaimana mungkin diperlukan agar menjadi sah dan dapat diberlakukan.</li>
      <li type="A"><strong>Tidak ada pengesampingan.</strong> Kegagalan suatu pihak dalam menggunakan hak-haknya dalam hal terjadi pelanggaran terhadap Ketentuan oleh pihak lain tidak akan dianggap sebagai pengesampingan dari hak-haknya berdasarkan Ketentuan atau berdasarkan hukum yang berlaku.</li>
      <li type="A"><strong>Keadaan Kahar.</strong> Tidak ada pihak mana pun yang akan bertanggung jawab kepada pihak lain atau yang dianggap melanggar Ketentuan karena keterlambatan atau kegagalan dalam melaksanakan kewajibannya yang disebabkan oleh peristiwa Keadaan Kahar. Pada waktu terjadinya peristiwa Keadaan Kahar, Creatory dapat, atas pilihannya sendiri, menangguhkan seluruh atau sebagian dari pelaksanaan/pemenuhan kewajiban-kewajibannya berdasarkan Ketentuan ini sementara peristiwa atau keadaan tersebut masih berlangsung. Apabila salah satu Peristiwa Keadaan Kahar tetap berlangsung selama jangka waktu yang melebihi satu (1) bulan, Creatory dapat memberi tahu Anda bahwa pihaknya akan mengakhiri saat itu juga atas Ketentuan atau hubungannya dengan Anda.</li>
      <li type="A"><strong>Hukum yang mengatur.</strong> Ketentuan ini diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia.</li>
      <li type="A"><strong>Penyelesaian sengketa.</strong> Setiap sengketa, pertentangan, perselisihan atau gugatan yang timbul dari atau sehubungan dengan Ketentuan ini, termasuk keberadaan, keabsahan, interpretasi, pelaksanaan, pelanggaran atau pengakhirannya atau sengketa apapun terkait dengan kewajiban tidak tertulis yang timbul dari atau terkait dengannya akan dirujuk ke dan diselesaikan secara final sesuai dengan Peraturan Arbitrase Badan Arbitrase Nasional Indonesia. Tempat arbitrase berkedudukan di Jakarta. Proses arbitrase akan diadakan dalam bahasa Indonesia.</li>
      <li type="A"><strong>Layanan Pengaduan. </strong> Untuk setiap layanan pengaduan konsumen, anda berhak menghubungi Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga ke nomor 0853-1111-1010 (whatsapp).</li>
      <li type="A"><strong>Pihak Ketiga.</strong> Creatory juga telah bekerja sama dengan pihak ketiga lainnya terkait dengan operasional webnya, termasuk namun tidak terbatas pada pihak ketiga penyedia fitur suara tertentu. Creatory dan Anda terikat dengan ketentuan penggunaan sebagaimana dijelaskan pada tautan berikut: <a href="https://notificationsounds.com/terms-of-use">https://notificationsounds.com/terms-of-use</a></li>
      </li>
      </li>
      </ol>
    `,
  },
];

export default TOS;
