const FAQ = [
  {
    key: "1",
    question: "Apa yang terjadi pada Creatory?",
    answer:
      "Creatory saat ini berhenti beroperasi untuk sementara. Terima Kasih telah menjadi bagian dari perjalanan Creatory hingga hari ini.",
  },
  {
    key: "2",
    question: "Apakah saya masih bisa mengakses akun Creatory saya?",
    answer:
      "Akun Creatory-mu sudah tidak bisa diakses lagi, namun jangan khawatir. Jika kamu ingin cek balance dan reward mu atau ingin mengetahui detail tentang akun mu, silakan kirimkan email pertanyaan ke \nemail : admin@creatory.id dengan subjek “Tanya Akun Creatory Saya” ",
  },
  {
    key: "3",
    question: "Apakah akan ada Creatory Hypes lagi?",
    answer: "Untuk saat ini Creatory Hypes akan diberhentikan.",
  },
  {
    key: "4",
    question:
      "Reward saya masih belum dicairkan dananya, saya bisa ajukan lewat mana?",
    answer: `Silakan kirimkan pertanyaanmu terkait reward dengan mengikuti langkah-langkah berikut ini: 
    <br><strong>Email Request:</strong>
    <ol><li>Subject email: [user id creatory] - [Hypes Withdrawal Request]</li>
    <li>Email ke: admin@creatory.id</li>
    <li>Body email format:
    <ol type="a">
    <li>
    Bersama dengan email ini saya ingin mengajukan penarikan dana dari akun creatory dengan detil sebagai berikut:
    <ol type="i">
    <li>
    User ID Creator
    </li>
    <li>
    Nama sesuai KTP
    </li>
    <li>
    No. HP 
    </li>
    <li>
    Bank Name
    </li>
    <li>
    Bank Account Name
    </li>
    <li>
    Bank Account Number
    </li>
    <li>
    Jumlah dana yang ingin ditarik: 
    </li>
    </ol>
    </li>
    </ol>
    </li>
    </ol><br><strong>Admin Verification</strong><br>Setelah menerima email pengajuan yang dilakukan oleh creators tim admin akan melakukan pengecekan terlebih dahulu, dalam waktu 2x24 jam akan diberikan feedback terkait proses penarikan dana nya. 
    <br><br><strong>Request Verified</strong><br>Setelah data-data pengajuan sudah diverifikasi, proses pengiriman dana akan dilakukan selambat-lambatnya 14 hari kerja.`,
  },
];

export default FAQ;
