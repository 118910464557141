interface IProps {
  size?: number;
  fill?: string;
  rotate?: number;
}

const SvgIcon = (props: IProps) => {
  const { fill, size = 16, rotate } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotate} 0 0)`}
    >
      <path
        d="M8.00001 5.46755C7.53335 5.46755 7.06668 5.64755 6.71335 6.00089L2.36668 10.3476C2.17335 10.5409 2.17335 10.8609 2.36668 11.0542C2.56001 11.2476 2.88001 11.2476 3.07335 11.0542L7.42001 6.70755C7.74001 6.38755 8.26001 6.38755 8.58001 6.70755L12.9267 11.0542C13.12 11.2476 13.44 11.2476 13.6333 11.0542C13.8267 10.8609 13.8267 10.5409 13.6333 10.3476L9.28668 6.00089C8.93335 5.64755 8.46668 5.46755 8.00001 5.46755Z"
        fill={fill || "#616161"}
      />
    </svg>
  );
};

export default SvgIcon;
