import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import parse from "html-react-parser";
import TOS from "../constant/tos";

const Tnc = () => {
  return (
    <Row justify="center" className="w-100 mt-2 pl-2 pr-2 text-justify">
      <Col>
        <Typography.Text className="heading-s-medium text-color-primary-purple">
          Term of Service
        </Typography.Text>
      </Col>
      <Col>
        <Typography.Text className="text-m-regular">
          {parse(TOS.find((x) => x.lang === "en")?.content || "")}
        </Typography.Text>
      </Col>
      <Divider />
      <Col>
        <Typography.Text className="heading-s-medium text-color-primary-purple">
          Ketentuan Layanan
        </Typography.Text>
      </Col>
      <Col>
        <Typography.Text className="text-m-regular">
          {parse(TOS.find((x) => x.lang === "id")?.content || "")}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default Tnc;
