const POLICY = [
  {
    lang: "en",
    content: `This Privacy Policy govern the collection, type, use, disclosure and retention of your personal data as a
  user and is applied between you (“<strong>User</strong>”, “<strong>you</strong>”, ”<strong>your</strong>”) and PT WHIM Management Indonesia when
  you are registered as a User of Creatory Platform or when you use Creatory Services. <br><br>

  <strong>This Privacy Policy govern the collection, type, use, disclosure and retention of your personal data as a user and is applied between you (“User”, “you”, "your") and PT WHIM Management Indonesia when you are registered as a User of Creatory Platform or when you use Creatory Services.</strong><br><br>
  
  Creatory collects and uses your personal data with your knowledge and consent and typically when you:
  <br><br>
  <ul> 
    <li>Use Creatory Platform or Services that are offered or for which you may choose to opt-in.</li>
    <li>Make User enquiries, register as User or use the Services.</li>
    <li>Respond to communications from Creatory (such as SMS, emails, questionnaires or surveys).</li>
    <li>Interact with Creatory’s websites or mobile application, such as submitting an application form, completing survey form, use available Services. (If your browser has Internet cookies enabled, it can facilitate Creatory’s tracking of personal preferences, pages visited etc.)</li>
    <li>Participate on Creatory’s social media pages.</li>
    <li>Participate in Creatory’s promotional events.</li>
    <li>Provide information through Creatory’s customer (User) service or any other Affiliates or dealings with Creatory by which you have consented to provide your personal data.</li>
  </ul>
  <strong>Provide information through Creatory’s customer (User) service or any other Affiliates or dealings with Creatory by which you have consented to provide your personal data.</strong>
  <br><br>
  The types of personal data that Creatory may collect include, but are not limited to:
  <br><br>
  <ul> 
    <li>Contact information (such as name, address, email address and telephone number).</li>
    <li>Identification information (such as Citizen ID Card, passport and date of birth).</li>
    <li>Demographic information (such as age range, gender).</li>
    <li>Photograph, such as those that you may submit for your account profile picture.</li>
    <li>Banking information or e-money account information.</li>
    <li>Type and version of operating system, hardware version, device settings, software types, battery and signal strength, screen resolution, device ID (International Mobile Equipment Identity), manufacturer and model, language, and Internet browser type and version.</li>
    <li>Geographic location information, such as location inferred from your IP address or GPS.</li>
    <li>Our Services may allow you to use biometric information for identification or authentication or to take advantage of specific features. Biometric data may include your fingerprint, voice, audio, video or similar unique physical characteristics.</li>
    <li>We collect information from and about a wide variety of technologies (may include computers, phones and tablets) where our Platform or Services are used. The kind of information collected depends on the technology, the use, and the device and personal settings.</li>
  </ul>
  <strong>How we use your personal data:</strong>
  <br><br>
  <ul>
    <li>To provide you with our Services or access to the Platform.</li>
    <li>To notify you about changes to the features of our Platform and Services.</li>
    <li>To understand how you interact with our Platform or Services and provide you with the best experiences.</li>
    <li>To provide you with the up-to-date security, versions, features, options and controls associated with your systems or devices.</li>
    <li>To send you service messages about our Services or account registration.</li>
    <li>To process your Creatory balance transfer or responds to User request.</li>
    <li>For business operations perform accounting, auditing, billing, reconciliation and collection activities, including crime, abuse or fraud monitoring and prevention, protecting our legal rights, and performing contractual obligations.</li>
    <li>To testing, modifying, improving or developing our new products, services and technologies, to identify trends and for security purposes.</li>
  </ul>
  <strong>How we share your personal data:</strong>
  <br><br>
  <ul>
    <li>We work with other service providers and when we share your personal data with these companies, we put in place appropriate measures to limit the use of your personal data only for legal and authorized purposes that are consistent with this Privacy Policy, as well as appropriate confidentiality and security measures. We also share personal data when required by law or to respond to legal process; to protect our Users; to maintain the security of our Platform or Services; and to protect our legal rights.</li>
    <li>Our service providers may require personal data to provide services we have need for, including but not limited to, provision of the Services, data analysis, IT services, auditing, balance transfer processing or customer (User) service. We use a wide variety of software and tools at Creatory and we process personal information using these tools as a regular course of business. Our contracts with services providers or vendors include provisions to protect your information and limit its use.</li>
    <li>We also share non-personally identifiable information, such as anonymized or aggregated information, with service providers for various purposes such as analysis, identifying trends in the areas of our Services and to help research and develop new services.</li>
    <li>We have relationships with third parties that are not service providers or vendors but are working with us to offer certain opportunities such as hypes. For third parties or uses not described in this Privacy Policy, we share your personal data only with a lawful basis to do so.</li>
    <li>We may disclose personal data to collaboration partners to conduct research to improve our technology, or the use of our technology, in various fields of study and to identify new uses or design new services.</li>
    <li>If we are obligated or required by the applicable law and regulatory requirement, court order, administrative agency, government, statutory or regulatory bodies, we may disclose your personal information as a compliance with prevailing law, regulation, detection or investigation of crime or fraudulent.</li>
    <li>We may disclose your personal data as part of a contemplated or actual corporate transaction such as a reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings).</li>
    <li>We may transfer your personal data across geographical borders to other entities, provided they ensure the same level of protection as us. The transfer of personal data is carried out under our standard contracts with appropriate data protection clauses or data transfer agreements with similar rights and obligations for the parties receiving such information to protect the security and confidentiality of your personal data.</li>
  </ul>
  <strong>Retention</strong>
  <br><br>
  Your personal data that has been collected and retained as long as required to meet the purposes mentioned above. We may store your personal data to provide the Services you have requested, or for other essential purposes, such as complying with our legal obligations, resolving disputes, and enforcing our terms or policies. The retention period for storage of personal data is based on the requirements of applicable laws. However, in the absence of any relevant laws, personal data will be stored where it is required for business purposes. The Users are entitled to request deletion their data and/or account by submitting such request to Creatory's email and/or live chat as provided in the Creatory website.
  <br><br>
  <strong>Security</strong>
  <br><br>
  We endeavor to process your information in a safe environment by preventing any unauthorized or unlawful processing of personal data or accidental loss or destruction of, or damage to such information. Users of our Services must also do their part in protecting the data, systems, networks, and service they are utilizing. No technology, data transmission or system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the password to your User Account has been compromised), please immediately notify or contact us.
  <br><br>
  <strong>By submitting your personal data to us, you acknowledge and agree that:</strong>
  <br><br>
  <ul>
  <li>You have read and understood this Privacy Policy and agree to the use of your personal data as set out herein.
  </li>
  <li>Your personal data may be transferred and processed worldwide, including countries that may not be deemed to provide the same level of data protection as your home country, for the purposes and in the manner specified in this Privacy Policy.
  </li>
  <li>All your representations are true and correct to the best of your knowledge, and you have not knowingly omitted any related information of adverse nature.
  </li>
  </ul>
  <strong>Contact us</strong>
  <br><br>
  If you have any questions or concerns about this Privacy Policy you can contact us at legal@creatory.id.
  <br><br>
  <strong>Updates to the Privacy Policy
  </strong>
  <br><br>
  Creatory reserves the right to update this Privacy Policy, if needed and based on its sole discreation. This Privacy Policy shall prevail over any of its previous versions.
  <br><br>
  Last updated on: July 21, 2021
  `,
  },
  {
    lang: "id",
    content: `Kebijakan Privasi ini mengatur mengenai pengumpulan, jenis, penggunaan, pengungkapan, dan penyimpanan data pribadi Anda sebagai Pengguna dan berlaku antara Anda (“<strong>Pengguna</strong>”, “<strong>Anda</strong>”, ”<strong>milik Anda</strong>”) 
    dan PT WHIM Management Indonesia ketika Anda terdaftar sebagai Pengguna Platform Creatory atau ketika Anda menggunakan Layanan Creatory. <br><br>

  <strong>Bagaimana kami mengumpulkan data pribadi Anda:.</strong><br><br>
  
  Creatory mengumpulkan dan menggunakan data pribadi Anda dengan sepengetahuan dan persetujuan Anda dan biasanya pada saat Anda:
  <br><br>
  <ul> 
   <li>menggunakan Platform atau Layanan Creatory yang ditawarkan atau yang dapat Anda pilih untuk menggunakannya.</li>
   <li>Mengajukan pertanyaan Pengguna, mendaftar sebagai Pengguna atau menggunakan Layanan.</li>
   <li>Menanggapi komunikasi dari Creatory (seperti SMS, surat elektronik (email), kuesioner atau survei).</li>
   <li>Berinteraksi dengan situs web atau aplikasi seluler Creatory, seperti mengirimkan formulir aplikasi, mengisi formulir survei, menggunakan Layanan yang tersedia. (Apabila browser Anda mengaktifkan cookie internet, hal tersebut dapat memfasilitasi Creatory untuk melakukan pelacakan preferensi pribadi, halaman yang dikunjungi, dan lainnya).</li>
   <li>Berpartisipasi di laman media sosial Creatory.</li>
   <li>Berpartisipasi dalam acara promosi Creatory.</li>
   <li>Memberikan informasi melalui layanan pelanggan (Pengguna) Creatory atau melalui Afiliasi atau transaksi lainnya dengan Creatory di mana Anda telah menyetujui untuk memberikan data pribadi Anda.</li>
  </ul>
  <strong>Apa sajakah data pribadi yang kami kumpulkan dari Anda:</strong>
  <br><br>
  Jenis-jenis data pribadi yang dapat Creatory kumpulkan termasuk, namun tidak terbatas pada:
  <br><br>
  <ul> 
    <li>Informasi kontak (seperti nama, alamat, alamat surat elektronik (e-mail) dan nomor telepon).</li>
    <li>Informasi identifikasi (seperti Kartu Tanda Penduduk (KTP), paspor, tanggal lahir).</li>
    <li>Informasi demografis (seperti rentang usia, jenis kelamin).</li>
    <li>Foto, seperti yang dapat Anda kirimkan untuk gambar profil akun Anda</li>
    <li>Informasi perbankan atau akun uang elektronik.</li>
    <li>Jenis dan versi sistem operasi, versi perangkat keras, pengaturan perangkat, jenis perangkat lunak, kekuatan baterai dan sinyal, resolusi layer, identitas perangkat (International Mobile Equipment Identity), produsen dan model, bahasa, serta jenis dan versi browser internet.</li>
    <li>Informasi lokasi geografis, seperti lokasi yang didapatkan dari alamat IP atau GPS Anda.</li>
    <li>Layanan kami memungkinkan Anda menggunakan informasi biometrik untuk melakukan identifikasi atau otentikasi atau untuk memanfaatkan fitur-fitur tertentu. Data biometrik dapat mencakup sidik jari, suara, audio, video, atau karakteristik fisik unik serupa.</li>
    <li>Kami mengumpulkan informasi dari dan tentang berbagai macam teknologi (bisa berupa komputer, telepon dan tablet) dimana Platform atau Layanan kami digunakan. Jenis informasi yang dikumpulkan tergantung pada teknologi, penggunaan, dan perangkat serta pengaturan pribadi. </li>
  </ul>
  <strong>Bagaimana kami menggunakan data pribadi Anda:</strong>
  <br><br>
  <ul>
   <li>Untuk menyediakan Anda dengan Layanan kami atau akses kepada Platform.</li>
   <li>Untuk memberi tahu Anda tentang perubahan pada fitur Platform dan Layanan kami.</li>
   <li>Untuk memahami bagaimana Anda berinteraksi dengan Platform atau Layanan kami dan memberi Anda pengalaman terbaik.</li>
   <li>Untuk memberi Anda keamanan, versi, fitur, opsi, dan kontrol terbaru yang terkait dengan sistem atau perangkat Anda.</li>
   <li>Untuk mengirimkan pesan layanan kepada Anda tentang Layanan kami atau pendaftaran akun.</li>
   <li>Untuk memproses pengiriman saldo Creatory Anda atau menanggapi permintaan Pengguna.</li>
   <li>Untuk operasi bisnis melakukan kegiatan akuntansi, audit, penagihan, rekonsiliasi dan pengumpulan, termasuk pemantauan dan pencegahan kejahatan atau penipuan, melindungi hak-hak hukum kami, dan melakukan kewajiban berdasarkan kontrak.</li>
   <li>Untuk menguji, memodifikasi, meningkatkan atau mengembangkan produk, layanan dan teknologi baru kami, untuk mengidentifikasi tren, dan untuk tujuan keamanan.</li>
  </ul>
  <strong>Bagaimana kami mengungkapkan data pribadi Anda:</strong>
  <br><br>
  <ul>
    <li>Kami bekerja dengan penyedia layanan lainnya dan ketika kami memberikan data pribadi Anda dengan perusahaan-perusahaan tersebut, kami menerapkan langkah-langkah yang tepat untuk membatasi penggunaan data pribadi Anda hanya untuk alasan yang sesuai hukum dan alasan penggunaan yang sah sesuai dengan Kebijakan Privasi ini, serta langkah-langkah kerahasiaan dan keamanan yang sesuai. Kami juga mengungkapkan data pribadi ketika diminta oleh hukum atau untuk menangani proses hukum; untuk melindungi Pengguna kami; untuk menjaga keamanan Platform atau Layanan kami; dan untuk melindungi hak-hak hukum kami.</li>
    <li>Penyedia layanan kami mungkin memerlukan data pribadi untuk menyediakan layanan yang kami butuhkan untuk, termasuk namun tidak terbatas pada, penyediaan Layanan, analisis data, layanan IT, audit, pemrosesan pengiriman saldo atau layanan pelanggan (Pengguna). Kami menggunakan berbagai macam perangkat lunak dan alat di Creatory dan kami memproses data pribadi menggunakan alat-alat ini sebagai kegiatan bisnis pada umumnya. Kontrak kami dengan penyedia layanan dan vendor mencakup ketentuan untuk melindungi informasi Anda dan membatasi penggunaannya.</li>
    <li>Kami juga mengungkapkan informasi yang tidak dapat diidentifikasi secara pribadi, seperti informasi yang di anonimkan atau dikumpulkan, dengan penyedia layanan untuk tujuan seperti analisis, mengidentifikasi tren di bidang Layanan kami dan untuk membantu penelitian dan mengembangkan layanan baru.</li>
    <li>Kami memiliki hubungan dengan pihak ketiga yang bukan merupakan penyedia layanan atau vendor namun bekerja bersama kami untuk menawarkan peluang tertentu seperti hypes. Untuk pihak ketiga atau penggunaan yang tidak dijelaskan dalam Kebijakan Privasi ini, kami memberikan data pribadi Anda hanya dengan dasar hukum yang sah.</li>
    <li>Kami dapat mengungkapkan data pribadi kepada mitra kolaborasi untuk melakukan penelitian untuk meningkatkan teknologi kami, atau penggunaan teknologi kami, di berbagai bidang studi dan untuk mengidentifikasi penggunaan baru atau merancang layanan baru.</li>
    <li>Apabila kami diwajibkan atau diharuskan oleh hukum dan persyaratan peraturan yang berlaku, perintah pengadilan, badan administratif atau badan pemerintah atau badan pembentuk peraturan, kami dapat mengungkapan data pribadi Anda sebagai kepatuhan terhadap hukum, peraturan yang berlaku atau deteksi atau investigasi kejahatan atau penipuan.</li>
    <li>Kami dapat mengungkapkan data pribadi Anda sebagai bagian dari transaksi korporasi yang direncanakan atau aktual seperti reorganisasi, merger (penggabungan), penjualan, usaha patungan, pengalihan, transfer atau disposisi lainnya dari semua atau sebagian dari bisnis, aset, atau saham kami (termasuk sehubungan dengan kepailitan atau proses serupa).</li>
    <li>Kami dapat mentransfer data pribadi Anda melintasi batas geografis ke entitas lain, asalkan mereka memastikan tingkat perlindungan yang sama dengan yang kami lakukan. Pengiriman data pribadi dilakukan berdasarkan kontrak standar kami dengan klausul perlindungan data yang sesuai atau perjanjian pengiriman data dengan hak dan kewajiban serupa bagi pihak yang menerima informasi tersebut untuk melindungi keamanan dan kerahasiaan data pribadi Anda.</li>
  </ul>
  <strong>Penyimpanan</strong>
  <br><br>
  Data pribadi Anda yang telah dikumpulkan dan akan disimpan selama jangka waktu diperlukan untuk memenuhi tujuan yang disebutkan di atas. Kami dapat menyimpan data pribadi Anda untuk menyediakan Layanan yang Anda minta, atau untuk tujuan penting lainnya, seperti mematuhi kewajiban hukum kami, menyelesaikan sengketa, dan melaksanakan kebijakan-kebijakan kami. Periode penyimpanan data pribadi didasarkan pada persyaratan hukum yang berlaku. Namun, jika tidak ada undang-undang yang relevan, data pribadi akan disimpan selama waktu yang diperlukan untuk tujuan bisnis. Pengguna berhak meminta penghapusan data dan/atau akun mereka dengan cara melakukan permohonan melalui email Creatory dan/atau live chat yang telah disediakan dalam situs web Creatory.
  <br><br>
  <strong>Keamanan</strong>
  <br><br>
  Kami berusaha untuk memproses informasi Anda dalam lingkungan yang aman dengan mencegah pemrosesan data pribadi yang tidak sah atau melanggar hukum atau kehilangan atau kerusakan yang tidak disengaja, atau kerusakan pada informasi tersebut. Pengguna Layanan kami juga harus melakukan tugasnya dalam melindungi data, sistem, jaringan, dan layanan yang mereka manfaatkan. Tidak ada teknologi, transmisi data atau sistem yang dapat dijamin 100% aman. Jika Anda memiliki alasan untuk meyakini bahwa interaksi Anda dengan kami tidak lagi aman (misalnya, jika Anda merasa bahwa kata sandi ke Akun Pengguna Anda telah disusupi), harap segera memberi tahu atau hubungi kami.
  <br><br>
  <strong>Dengan memberikan data pribadi Anda kepada kami, Anda mengakui dan menyetujui bahwa:</strong>
  <br><br>
  <ul>
  <li>Anda telah membaca dan memahami Kebijakan Privasi ini dan menyetujui penggunaan data pribadi Anda sebagaimana diatur dalam Kebijakan Privasi ini.</li>
  <li>Data pribadi Anda dapat dikirimkan dan diproses di seluruh dunia, termasuk negara-negara yang mungkin tidak dianggap memberikan tingkat perlindungan data yang sama dengan negara asal Anda, untuk tujuan dan dengan cara yang ditentukan dalam Kebijakan Privasi ini.</li>
  <li>Semua pernyataan Anda adalah benar dan tepat sesuai pengetahuan Anda, dan Anda tidak dengan sengaja menghilangkan informasi terkait yang dapat merugikan.</li>
  </ul>
  <strong>Hubungi kami</strong>
  <br><br>
  Apabila Anda memiliki pertanyaan atau pertimbangan lain tentang Kebijakan Privasi ini Anda dapat menghubungi kami di legal@creatory.id<br><br>
  <strong>Pembaruan terhadap Kebijakan Privasi</strong>
  <br><br>
  Creatory berhak untuk memperbarui Kebijakan Privasi ini, jika diperlukan dan berdasarkan kebijakannya sendiri. Kebijakan Privasi ini berlaku menggantikan versi terdahulunya.
  <br><br>
  Terakhir diperbarui pada: 21 Juli 2021
  `,
  },
];

export default POLICY;
