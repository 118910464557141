import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./component/layout";
import HomePage from "./pages/home";
import Policy from "./pages/policy";
import Tos from "./pages/tos";
import "./styles/main.scss";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Layout>
  );
}

export default App;
