import { Col, Image, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ASSET from "../../constant/assets";

interface IProps {
  children: React.ReactNode;
}

const Layout = (props: IProps) => {
  const { children } = props;
  const navigate = useNavigate();
  return (
    <div className="layout-container">
      <Row justify="center" className="layout-header">
        <Image
          onClick={() => navigate("/")}
          className="use-pointer"
          preview={false}
          src={ASSET.LOGO_CREATORY}
        />
      </Row>
      <div className="layout-content">{children}</div>
      <div style={{ marginTop: "auto" }}>
        <Row className="mb-2">
          <Col span={24} className="mt-3 text-center">
            <Typography.Text className="text-s-regular">
              Read about our{" "}
              <Typography.Text
                onClick={() => navigate("/policy")}
                className="text-s-regular text-color-primary-main use-pointer"
              >
                Privacy Policy
              </Typography.Text>{" "}
              and{" "}
              <Typography.Text
                onClick={() => navigate("/tos")}
                className="text-s-regular text-color-primary-main use-pointer"
              >
                Terms & Conditions
              </Typography.Text>
            </Typography.Text>
          </Col>
        </Row>
        <Row className="layout-footer">
          <Col span={24} className="text-center">
            <Typography.Text className="text-s-regular text-color-neutral-10">
              Copyright 2022 @Creatory. All right reserved.
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Layout;
