import { Col, Collapse, Image, Row, Typography } from "antd";
import ASSET from "../../constant/assets";
import FAQ from "../../constant/faq";
import parse from "html-react-parser";
import { IconArrow } from "../../component/icons";

const { Panel } = Collapse;

const HomePage = () => {
  return (
    <Row justify="center" className="w-100 pr-2 pl-2">
      <Col>
        <Image preview={false} src={ASSET.HERO_1} />
      </Col>
      <Col className="pt-2">
        <Typography.Text className="heading-s-medium">
          Dear Creatory users
        </Typography.Text>
      </Col>
      <Col className="pt-1 text-center">
        <Typography.Text className="text-m-regular text-color-neutral-80">
          Makasih yaa kamu udah jadi bagian dari Creatory. Mungkin kamu
          penasaran ada apa sih dengan Creatory, kan? Yuk cari tau lewat email
          atau kamu bisa lihat FAQ di bawah ini yaa
        </Typography.Text>
      </Col>
      <Col span={24} className="pt-1 text-center">
        <Typography.Text className="heading-s-medium">FAQ</Typography.Text>
      </Col>
      <Col span={24} className="pt-3">
        <Collapse
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <IconArrow rotate={isActive ? 0 : 180} />
          )}
        >
          {FAQ.map((x) => {
            return (
              <Panel header={x.question} key={x.key}>
                {parse(x.answer)}
              </Panel>
            );
          })}
        </Collapse>
      </Col>
    </Row>
  );
};

export default HomePage;
